@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital@0;1&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Plus Jakarta Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  max-width: 1536px;
  margin: 0 auto;
}

button {
  box-shadow: 1px 1px 2px 0px #8FA81133 inset;
  box-shadow: 1px 1px 2px 0px #F0F4F980 inset;
  box-shadow: -1px -1px 2px 0px #F0F4F980 inset;
}