.nav-link {
    color: #939393;
    font-size: 12px;
    font-weight: 500;
}

.nav-link:hover {
    color: #242424
}

.active .nav-link {
    color: #242424
}